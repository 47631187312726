<template>
  <div style="position: relative">
    <vehicle-card @vehicleSelected="onVehicleSelected"></vehicle-card>
    <div id="map"></div>
  </div>
</template>

<script>
import VehicleCard from "@/components/map/VehicleCard";
import {HTMLMapMarker} from "@/utils/map-custom-marker";
export default {
  components: {VehicleCard},
  data() {
    return {
      map: null,
      marker: null,
    }
  },
  methods: {
    initMap(mapId) {
      let latLng = new google.maps.LatLng(59.334591, 18.063240);
      this.map = new google.maps.Map(mapId, {
        center: latLng,
        zoom: 8,
        mapTypeControl: false,
        scaleControl: true,
        zoomControl: true,
      });

    },
    onVehicleSelected(data) {
      if(this.marker) {
        this.marker.setMap(null);
      }

      this.addMarker(data.latitude, data.longitude);
      this.fitBounds(data.latitude, data.longitude);
    },
    addMarker(latitude, longitude) {
      this.marker = new HTMLMapMarker({
        latlng: new google.maps.LatLng(latitude, longitude),
        map: this.map,
        html: `<div class="vehicle-marker"><div class="vehicle-marker__inner"></div></div>`
      });
    },
    fitBounds(latitude, longitude) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend( new google.maps.LatLng(latitude, longitude))
      this.map.fitBounds(bounds);
      this.map.setZoom(13);
    }
  },
  mounted() {
    this.initMap(document.getElementById('map'))
  }
}
</script>

<style scoped>
#map {
  height: 92vh;
  width: 100%;
  /*border-radius: 5px;*/
}
</style>

<style lang="scss">
.vehicle-marker {
  position: absolute;
  left: -45px;
  top: -45px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: rgba(66, 100, 251, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    width: 30px;
    height: 30px;
    border: 3px solid #ffffff;
    background: var(--color-primary);
    border-radius: 50%;
  }
}
</style>
