<template>
  <div class="vehicle-card">
    <div class="vehicle-card__search">
      <v-select class="search-vehicle" placeholder="Sök på reg.nr" :options="vehicles" @input="getVehicleLocation" :reduce="item => item.licencePlate" label="licencePlate">
        <template #no-options>Inga tillgängliga val.</template>
      </v-select>
    </div>

    <div class="mt-4 vehicle-location" v-if="address">
      {{address}}
    </div>
  </div>
</template>

<script>
import vehiclesHttp from '@/http/vehicles'

export default {
  data() {
    return {
      vehicles: [],
      address: null
    }
  },
  computed: {
    isCompanyAdmin() { return this.$store.getters.isCompanyAdmin },
    userEmail() { return this.$store.state.user.email }
  },
  methods: {
    async getVehicleLocation(licencePlate) {
      try {
        const data = await vehiclesHttp.getCurrentLocation(licencePlate);
        this.$emit('vehicleSelected', data);
        this.address = data.address;
      } catch (e) {

      }
    }
  },
  async created() {
    try {
      const bindStatus = this.isCompanyAdmin ? null : 'bound'
      const {vehicles} = await vehiclesHttp.getVehicles({boxBindStatus: 'bound', bindStatus});
      this.vehicles = vehicles;
      if(this.isCompanyAdmin) {
        this.vehicles = this.vehicles.filter(item => {
          if(this.userEmail === item.user.email) return true
          return item.vehicleCategory !== 'private'
        })
      }
    } catch (e) {

    }
  }
}
</script>

<style lang="scss" scoped>
.vehicle-card {
  background: #ffffff78;
  width: 400px;
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 1000000;
  padding: 20px;
  border-radius: 20px;
}
.vehicle-location {
  border-radius: 20px;
  background: #ffffff;
  padding: 20px;
  font-size: 14px;
}
</style>

<style lang="scss">
.search-vehicle {
  .vs__dropdown-toggle {
    background: #ffffff !important;
  }
}
</style>
